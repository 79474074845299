import { TeamSeasonConfig } from '@/types/leauge-config';

export const Season202425: TeamSeasonConfig = {
  divisionId: 16262,
  standings: [
    {
      divisionId: 16266,
      playoffCut: 4,
      name: 'Gruppe West',
    },
    {
      divisionId: 16265,
      playoffCut: 4,
      name: 'Gruppe Ost',
    },
  ],
  playoffId: 17392,
  teamId: 34181,
  seasonLabel: '2024-25',
  streamingFallbackUrl: 'https://www.red.sport/en-at/page/oel'
};

export default Season202425;
